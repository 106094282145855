import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  googleMapsURL?: string
  image?: ImageProps
  poiList?: PoiProps[]
  languageCode: string
}

export const Interactive = memo(function Interactive({
  googleMapsURL,
  languageCode,
  poiList,
  image,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container className="blended">
      <Blended>
        <Image {...image} />
      </Blended>

      {poiList
        ? poiList.map((item, index) => <Poi key={index} {...item} />)
        : null}

      {googleMapsURL ? (
        <CTA
          label={useVocabularyData('open-in-google-maps', languageCode)}
          rel="noopener"
          target="_blank"
          URL={googleMapsURL}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 47.5rem;
    height: auto;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Blended = styled.div`
  mix-blend-mode: difference;

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    mix-blend-mode: luminosity;
  }

  @media (max-width: 1199px) {
    mix-blend-mode: normal;
  }
`

const CTA = styled(Button)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 1.375rem 2.125rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  svg {
    margin-left: 0.5rem;
    position: relative;
    top: auto;
    right: auto;
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
    left: 0;
    text-align: center;

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight5};
    }
  }
`
